/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from "./App.vue";

// Composables
import { createApp } from "vue";

// Plugins
import { registerPlugins } from "@/plugins";

import { initializeAuth } from "@/api/auth";
import axios from "axios";
import HighchartsVue from "highcharts-vue";

import { createI18n } from "vue-i18n";

import * as Sentry from "@sentry/vue";

import router from "./router";

const apiRegex = new RegExp(import.meta.env.VITE_API_REGEX);

const i18n = createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: {},
});

export async function loadLocaleMessages(locale) {
    const messages = await import(`./locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
    i18n.global.locale = locale;
}

const userLocale = localStorage.getItem("locale");
if (userLocale) {
    loadLocaleMessages(userLocale);
} else {
    loadLocaleMessages("en");
}

initializeAuth();

axios.defaults.baseURL = "/api/v1/";
axios.defaults.headers.common.Accept = "application/json";

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://63bc347b1c4058c33a52f3fee75b4d64@o4507579018248192.ingest.de.sentry.io/4508019041828944",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [apiRegex],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

registerPlugins(app);
app.use(HighchartsVue);
app.use(i18n);

app.mount("#app");
