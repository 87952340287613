import { getToken, hasRole, isAuthenticated, setToken } from "@/api/auth";
// Composables
import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        component: () => import("@/layouts/default/Default.vue"),
        children: [
            {
                path: "",
                name: "Home",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: { requiresAuth: true, requiresRole: "user" },
                component: () =>
                    import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
            },
            {
                path: "/consumers",
                name: "Consumers",
                meta: { requiresAuth: true, requiresRole: "user" },
                component: () => import("@/views/Consumers.vue"),
                props: { isProducer: false },
                key: "consumers",
            },
            {
                path: "/producers",
                name: "Producers",
                meta: { requiresAuth: true, requiresRole: "user" },
                component: () => import("@/views/Consumers.vue"),
                props: { isProducer: true },
                key: "producers",
            },
            {
                path: "/price-profiles",
                name: "PriceProfiles",
                meta: { requiresAuth: true, requiresRole: "user" },
                component: () => import("@/views/PriceProfiles.vue"),
            },
            {
                path: "/price-profiles/:new",
                name: "CreatePriceProfile",
                meta: { requiresAuth: true, requiresRole: "user" },
                component: () => import("@/views/CreatePriceProfile.vue"),
            },
            {
                path: "/price-profiles/:id/edit",
                name: "EditPriceProfile",
                meta: { requiresAuth: true, requiresRole: "user" },
                component: () => import("@/views/EditPriceProfile.vue"),
            },
            {
                path: "/projects/:id/new",
                name: "CreateProject",
                meta: { requiresAuth: true, requiresRole: "user" },
                component: () => import("@/views/CreateProject.vue"),
            },
            {
                path: "/projects/:id",
                name: "EditProject",
                meta: { requiresAuth: true, requiresRole: "user" },
                component: () => import("@/views/LoadProfiles.vue"),
            },
            {
                path: "/projects/:projectId/loadprofile/:loadProfileId",
                name: "LoadProfile",
                meta: { requiresAuth: true, requiresRole: "user" },
                component: () => import("@/views/EditLoadProfile.vue"),
            },
        ],
    },
    {
        path: "/login",
        component: () => import("@/views/Login.vue"),
    },
    {
        path: "/unauthorized",
        name: "Unauthorized",
        component: () => import("@/views/Unauthorized.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const token = to.query.token || getToken();

    if ((token && !getToken()) || token !== getToken()) {
        setToken(token);
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!isAuthenticated()) {
            next({ name: "Unauthorized" });
        } else if (to.meta.requiresRole && !hasRole(to.meta.requiresRole)) {
            next({ name: "Unauthorized" });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
