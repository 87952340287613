<template>
  <router-view />
</template>

<script>
import { initializeAuth } from "@/api/auth";

export default {
    name: "App",
    created() {
        initializeAuth();
    },
};
</script>
